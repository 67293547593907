import { GatsbyImageProps } from 'gatsby-image';
import * as React from 'react';
import styled from 'react-emotion';

import AnimatedImage from '../components/AnimatedImage';
import SectionHeader from '../components/SectionHeader';

import { heights } from '../styles/variables';

interface ImageNode {
	node: {
		childImageSharp: GatsbyImageProps,
	};
}
interface PictureSectionProps {
	containerWidth?: number | undefined;
	edges: ImageNode[];
	extraContent?: React.ReactNode;
	onClick?: () => null;
	subText: string;
	text: string;
	width?: number;
}

const Masonry = styled.div`
	display: flex;
	flex-flow: row wrap;
	width: 100%;
`;

const SectionBackground = styled.div`
	justify-content: space-between;
	flex: 1;
	padding: 4px;
	margin-top: ${heights.nav - 42}px;
`;

const PictureSection: React.FunctionComponent<PictureSectionProps> = ({
	containerWidth,
	edges,
	extraContent,
	onClick,
	subText,
	text,
	width,
}) => {
	return (
		<SectionBackground>
			{text && <SectionHeader subText={subText} text={text} />}
			{extraContent && extraContent}
			<Masonry id="transcroller-body" className="aos-all masonry">
				{edges.map((imageNode: ImageNode, i: number) => {
					const fluid = imageNode.node.childImageSharp.fluid;
					return (
						<AnimatedImage
							containerWidth={containerWidth}
							fluid={fluid}
							key={`${fluid && fluid.src}_${i}`}
							onClick={onClick}
							index={i}
							width={width}
						/>
					);
				})}
			</Masonry>
		</SectionBackground>
	);
};

export default PictureSection;
