import styled from 'react-emotion';

import { colors, fonts } from '../variables';

interface H1Props {
	color?: string;
}

export const H1 = styled.h1`
	color: ${({ color }: H1Props) => color || colors.lightAccent};
	font-size: 36px;
	font-family: ${fonts.sansSerif};
	margin-bottom: 0;
	padding: 10px 15px;
	text-transform: uppercase;
`;
