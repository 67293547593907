import { GatsbyImageProps } from 'gatsby-image';
import { default as React } from 'react';
import { get } from 'lodash';
import { graphql } from 'gatsby';
import memoize from 'memoize-one';
import styled from 'react-emotion';

import Page from '../components/Page';
import Container from '../components/Container';
import IndexLayout from '../layouts';
import PictureSection from '../components/PictureSection';

import { fluidImage as fluidImageFragment } from '../queries/fragments';

import { breakpoints, colors, fonts } from '../styles/variables';

import { useContainerWidth, useWindowDimensions } from '../util/hooks';

interface ImageNode {
	node: {
		childImageSharp: GatsbyImageProps,
	};
}

interface ProjectsProps {
	data?: any;
}

const ProjectPics = styled.div`
	background-color: ${colors.darkAccent};
	padding: 0 12px;
	justify-content: space-around;
`;

interface WidthProps {
	containerWidth: number;
	width?: number;
}

const setPictureWidth = memoize((width: number, isMobileApp: boolean = false) => {
	const widthProp: WidthProps = { containerWidth: width };
	widthProp.width = 0.9;
	if (isMobileApp && width > breakpoints.md) {
		widthProp.width = 0.4;
	}
	return widthProp;
});

const TechStack = styled.div`
	align-self: center;
	font-family: ${fonts.sansSerif};
	font-size: 26px;
	margin: auto;
	margin-bottom: 15px;
	margin-top: 15px;
	padding: 10px;
	text-align: center;
	width: 80%;
	color: ${colors.light};
`;

const Projects: React.FunctionComponent<ProjectsProps> = props => {
	const edges: ImageNode[] = get(props, ['data', 'screenshots', 'edges'], []);
	const id = 'projectdescriptions';
	const containerWidth = useContainerWidth(`#${id}`, props);
	const { width: windowWidth } = useWindowDimensions();
	return (
		<IndexLayout>
			<Page>
				<Container>
					<ProjectPics id={id}>
						<PictureSection
							edges={edges.filter(imageNode => {
								const src = get(imageNode, 'node.childImageSharp.fluid.src', '');
								return !!src.match(new RegExp('nantum'));
							})}
							extraContent={
								<TechStack>
									React, Redux, D3, Node, Express, Webpack, HTML, SASS, Mocha,
									TypeScript
								</TechStack>
							}
							subText="Building OS to optimize commercial real estate operations."
							text="Nantum"
							{...setPictureWidth(containerWidth || windowWidth * 0.9)}
						/>
						<PictureSection
							containerWidth={containerWidth}
							edges={edges.filter(imageNode => {
								const src = get(imageNode, 'node.childImageSharp.fluid.src', '');
								return !!src.match(new RegExp('dock72'));
							})}
							extraContent={<TechStack>React Native, Node, iOS, Android</TechStack>}
							subText="Tenant apps for Android and iOS for building access and amenities."
							text="Dock 72 / Nantum Tenant"
							{...setPictureWidth(containerWidth || windowWidth * 0.9, true)}
						/>
						<PictureSection
							edges={edges.filter(imageNode => {
								const src = get(imageNode, 'node.childImageSharp.fluid.src', '');
								return !!src.match(new RegExp('rescue'));
							})}
							extraContent={<TechStack>React Native</TechStack>}
							subText="Emergency response system for the 21st century. 1st place in the geospatial intelligence category at the 2017 Real Estate Board of New York hackathon."
							text="Rescue"
							{...setPictureWidth(containerWidth || windowWidth * 0.9, true)}
						/>
						<PictureSection
							edges={edges.filter(imageNode => {
								const src = get(imageNode, 'node.childImageSharp.fluid.src', '');
								return !!src.match(new RegExp('wiki'));
							})}
							extraContent={
								<TechStack>
									React, Node, Express, AWS, Gulp, recurrentjs neural network, nlp
									language library
								</TechStack>
							}
							subText="Wikipedia rendered into poetic style with machine learning."
							text="Wikipoetry"
							{...setPictureWidth(containerWidth || windowWidth * 0.9)}
						/>
						<PictureSection
							edges={edges
								.filter(imageNode => {
									const src = get(
										imageNode,
										'node.childImageSharp.fluid.src',
										''
									);
									return !!src.match(new RegExp('sumo'));
								})
								.sort((a, b) => {
									const srcA = get(a, 'node.childImageSharp.fluid.src', '');
									const srcB = get(b, 'node.childImageSharp.fluid.src', '');
									return srcA > srcB ? -1 : 1;
								})}
							extraContent={
								<TechStack>HTML, socket.io, phaser.io game library</TechStack>
							}
							subText="Your phone becomes a controller as you wage tomato war on a central screen."
							text="Sumo Tomato"
							{...setPictureWidth(containerWidth || windowWidth * 0.9)}
						/>
					</ProjectPics>
				</Container>
			</Page>
		</IndexLayout>
	);
};

export default Projects;

export const fluidImage = fluidImageFragment;
export const pageQuery = graphql`
	query {
		screenshots: allFile(
			filter: { ext: { regex: "/jpg|png/" }, relativePath: { regex: "/projects.*/" } }
			sort: { fields: [relativePath], order: ASC }
		) {
			edges {
				node {
					...fluidImage
				}
			}
		}
	}
`;
