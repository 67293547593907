import * as React from 'react';
import styled from 'react-emotion';

import { H1 } from '../styles/components/_h1';
import { boxShadow, colors, fonts, widths } from '../styles/variables';

interface SectionHeaderProps {
	subText?: string;
	text: string;
}

const Group = styled.div`
	background-color: ${colors.dark};
	border: 1px solid ${colors.dark};
	border-radius: 8px;
	box-shadow: ${boxShadow};
	display: flex;
	flex-direction: column;
	padding: 24px;

	@media only screen and (max-width: ${widths.md}px) {
		padding: 8px;
	}
`;

const HeaderContainer = styled.div`
	display: inline-block;
	margin: 15px 0 45px 10%;
	min-width: 300px;
	max-width: 60%;
	width: 60vw;

	@media only screen and (max-width: ${widths.md}px) {
		margin: 35px 0px 10px 0px;
		width: 100%;
	}
`;

const Subtext = styled.div`
	align-self: 'center';
	color: ${colors.light};
	font-size: 22px;
	font-family: ${fonts.sansSerif};
	font-weight: 400;
	padding: 0 15px;
`;

const SectionHeader: React.FunctionComponent<SectionHeaderProps> = ({ subText, text }) => {
	return (
		<HeaderContainer>
			<Group>
				<H1 color={colors.lightAccent}>{text}</H1>
				<Subtext>{subText}</Subtext>
			</Group>
		</HeaderContainer>
	);
};

export default SectionHeader;
